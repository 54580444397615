import { useNavigate } from "react-router-dom";
import { Button } from "../Button";

function NavBar() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Button
        label="Rota ler localStorage"
        onClick={() => navigate("v2/ler_storage")}
      />
      <Button
        label="Rota gravar localStorage"
        onClick={() => navigate("v1/gravar_storage")}
      />
    </div>
  );
}

export { NavBar };
