import { useState } from "react";
import { Container } from "../../components/Container";
import { Button } from "../../components/Button";

function GravarStorage() {
  const [inputValue, setInputValue] = useState(null);

  function onChangeInput(e) {
    setInputValue(e.target.value);
  }

  function gravarStorage() {
    localStorage.setItem("inputValue", inputValue);

    setInputValue(null);
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
        }}
      >
        <label htmlFor="id-gravar">Gravar no Storage</label>
        <input
          id="id-gravar"
          style={{ width: "100%" }}
          value={inputValue}
          onChange={onChangeInput}
        />
      </div>

      <Button label="GravarStorage" onClick={gravarStorage} />
    </Container>
  );
}

export { GravarStorage };
