import { useState } from "react";
import { Button } from "../../components/Button";
import { Container } from "../../components/Container";

function LerStorage() {
  const [valueStorage, setValueStorage] = useState(null);

  function lerStorage() {
    setValueStorage(localStorage.getItem("inputValue"));
  }
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
        }}
      >
        <div>{valueStorage}</div>
      </div>

      <Button label="lerStorage" onClick={lerStorage} />
    </Container>
  );
}

export { LerStorage };
