import React from "react";
import { Routes, Route } from "react-router-dom";
import { LerStorage } from "./view/LerStorage";
import { GravarStorage } from "./view/GravarStorage";
import { Layout } from "./components/Layout";

function Rotas() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/v1/ler_storage" element={<LerStorage />} />
        <Route path="/v1/gravar_storage" element={<GravarStorage />} />
      </Route>
    </Routes>
  );
}

export default Rotas;
