function Button({ label, onClick, color, style }) {
  return (
    <button
      onClick={onClick}
      style={{
        width: "100%",
        padding: "16px 16px",
        margin: "12px",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer",
        backgroundColor: "#36a3b2" || color,
        color: "#fff",
        ...style,
      }}
    >
      {label}
    </button>
  );
}

export { Button };
